<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="p-0 card-header border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="px-3 pt-2"><h3 class="card-title">{{ giftwrapper.name}}</h3></li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-8" >
                                                    <div class="form-group">
                                                        <label for="company_id" class="control-label">Shop*</label>
                                                        <select v-model="form.company_id" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('company_id')}" :disabled="true">
                                                            <option v-for="company in companies" :key="company.id" :value="company.id">{{company.name}}</option>
                                                        </select>
                                                        <has-error :form="form" field="company_id"></has-error>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" v-if="giftwrapper.thumbnail != ''">
                                                        <figure class="float-right mt-2" style="width: 70%; height: auto;">
                                                            <img v-bind:src="giftwrapper.thumbnail" id="Brandimage" class="img-fluid" alt="img">
                                                        </figure>
                                                </div>
                                            </div>
                                            

                                            <div class="form-group">
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">
                                                        <label for="name" class="control-label">Name*</label>
                                                        <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" :disabled="true">
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="ordernumber" class="control-label">Nummer*</label>
                                                        <input v-model="form.ordernumber" name="ordernumber" id="ordernumber" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('ordernumber')}" :disabled="true">
                                                        <has-error :form="form" field="ordernumber"></has-error>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="price" class="control-label col-form-label col-form-label-sm">Preis (Brutto)*</label>
                                                        <input type="number" class="form-control form-control-sm" name="price" id="price" :class="{'is-invalid': form.errors.has('price')}" v-model="form.price" :disabled="true">
                                                        <has-error :form="form" field="price"></has-error>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group">
                                                <label for="description" class="control-label">Beschreibung</label>
                                                <input v-model="form.description" name="description" id="description" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('description')}" :disabled="true">
                                                <has-error :form="form" field="description"></has-error> 
                                            </div>

                                            <div class="form-group">
                                                <label for="image" class="control-label">Bild</label>
                                                <b-form-file id="image" size="sm" class="form-control" v-model="form.image" :class="{'is-invalid': form.errors.has('image')}" :disabled="true" />
                                                <has-error :form="form" field="image"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>

// import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "GiftwrapperDetails",
    title: "Geschenkkorbdetails",

    data() {
        return {
            url: process.env.VUE_APP_API_URL,
            token: '12345',
            form: new window.UploadForm({
                id: "",
                name: "",
                ordernumber: "",
                price: "", 
                description: "",
                image: [],
                company_id: '',
                _method: "PUT",
            }),
            // editor: ClassicEditor,
            // editorConfig: {
            //     simpleUpload: {
            //         uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
            //         withCredentials: true,
            //         headers: {
            //             'Accept': 'application/json',
            //             'Authorization': 'Bearer '+ this.$auth.token()
            //         }
            //     }

            // },
            companies: [],
            giftwrapper: {},
            editMode: false,
        }
    }, 

    methods:{
        
        editGiftwrapper() {
            this.form
                .post("/giftwrappers/"+this.giftwrapper.id)
                .then(() => {
                    this.loadGiftwrapper();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Geschenkkorb wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        loadGiftwrapper() {
            this.axios
                .get("/giftwrappers/" + this.$route.params.id)
                .then((response) => {
                    this.giftwrapper = response.data.data;

                    if(this.giftwrapper.description == null)
                    {
                        this.giftwrapper.description = "";
                    }
                    this.form.fill(this.giftwrapper);
                    this.form._method = "PUT";
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCompanies() {
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadGiftwrapper();
        this.loadCompanies();
    },
}
</script>

<style>

</style>